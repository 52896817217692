<template>
    <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-xl modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{
                            this.couponModel.id
                                ? (this.modeView ? "" : $t("button.edit"))
                                : $t("button.add")
                        }} {{ $t("module.coupons") }}</h5>
                </div>
                    <form @submit.prevent="submitData()">
                        <div class="modal-body">
                            <input
                                type="hidden"
                                class="form-control"
                                v-model.number="couponModel.id"
                            />

                            <div class="row">
                                <div class="col-md-8">
                                    <div class="row">
                                <div class="col-md-6 form-group">
                                    <label for="">{{ $t('coupons.coupon_code') }}* :</label>
                                    <div class="input-group">
                                        <input v-model="couponModel.code" :disabled="modeEdit"
                                        class="form-control"
                                        v-validate="{required:true,verify_coupon_code:true,min:8,max:32}"
                                        :class="{'is-invalid': errors.has('code')}"
                                        name="code" :data-vv-as="$t('coupons.coupon_code')"
                                        />
                                        <div class="input-group-append">
                                            <button :disabled="modeEdit" @click="generateCouponCode" type="button" class="btn btn-outline-secondary"><i class="fa fa-sync"></i></button>
                                        </div>
                                        <div v-show="errors.first('code')" class="custom-invalid-feedback" >{{ errors.first('code') }}</div>
                                    </div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="">{{ $t('common.judul') }}* :</label>
                                    <input v-model="couponModel.title"
                                    v-validate="{required:true,max:100 }"
                                    class="form-control"
                                    :class="{'is-invalid':errors.has('title')}"
                                    :disabled="modeView"
                                    name="title" :data-vv-as="$t('common.judul')"/>
                                    <div v-show="errors.first('title')" class="custom-invalid-feedback" >{{ errors.first('title') }}</div>
                                </div>

                                <div class="col-md-6 form-group">
                                    <label for="">{{ $t('common.subJudul') }} :</label>
                                    <input v-model="couponModel.subtitle"
                                    v-validate="{max:100 }"
                                    :disabled="modeView"
                                    class="form-control" name="subtitle" :data-vv-as="$t('common.subJudul')"
                                    :class="{'is-invalid':errors.has('subtitle')}"
                                    />
                                    <div v-show="errors.first('subtitle')" class="custom-invalid-feedback" >{{ errors.first('subtitle') }}</div>
                                </div>


                                <div class="col-md-6 form-group">
                                    <label for="">{{ $t('coupons.exchange_points') }}* :</label>
                                    <input v-model="couponModel.point" v-validate="'required'"
                                    :disabled="modeView"
                                    class="form-control" pattern="[0-9]" step="1" min="0" max="99999999"
                                    name="point" :data-vv-as="$t('coupons.exchange_points')"
                                    :class="{'is-invalid':errors.has('point')}" type="number"
                                    />
                                    <div v-show="errors.first('point')" class="custom-invalid-feedback" >{{ errors.first('point') }}</div>
                                </div>

                                <div class="col-md-6 form-group">
                                    <label for="">{{ $t('coupons.valid_until') }}* :</label>
                                    <date-time-picker :picker-value="couponModel.expired_date"
                                        :disabled="modeView"
                                        @update:changedValue="changedExpiredDate" ref="datePickerVue"
                                        :picker-id="'expired_date_'+couponModel.id" >
                                    </date-time-picker>
                                    <div v-show="errors.first('expired_date')" class="custom-invalid-feedback" >{{ errors.first('expired_date') }}</div>
                                </div>


                                <div class="col-md-6 form-group">
                                    <label for="">{{ $t('common.kategori') }}* :</label>
                                    <select-dropdown-multiple
                                        :is-disabled="modeView"
                                        :render-select="kategoriPromoRenderSelect"
                                        validate-rule="required"
                                        repo-name="kategoriPromo"
                                        @update:changedValue="onKategoriPromoSelected"
                                        ref="kategoriPromoSelectVue"
                                        :selected-dropdown="kategoriPromoIds"
                                        :id-select-dropdown="'categories_' + couponModel.id"
                                        ></select-dropdown-multiple>
                                    <div v-show="errors.first('categories')" class="custom-invalid-feedback" >{{ errors.first('categories') }}</div>
                                </div>

                                <div class="col-md-6 form-group">
                                    <label for="">{{ $t('coupons.usage_limit') }}:</label>
                                    <input v-model="couponModel.limit_coupon"  class="form-control"
                                    :disabled="modeView"
                                    name="limit_coupon" :data-vv-as="$t('coupons.usage_limit')"
                                    :class="{'is-invalid':errors.has('limit_coupon')}" type="number"
                                    />
                                    <div v-show="errors.first('limit_coupon')" class="custom-invalid-feedback" >{{ errors.first('limit_coupon') }}</div>
                                </div>

                                <div class="col-md-6 form-group">
                                    <label for="">{{ $t('coupons.usage_limit_member') }}:</label>
                                    <input v-model="couponModel.limit_coupon_member"  class="form-control"
                                    :disabled="modeView"
                                    name="limit_coupon_member" :data-vv-as="$t('coupons.usage_limit_member')"
                                    :class="{'is-invalid':errors.has('limit_coupon_member')}" type="number"
                                    />
                                    <div v-show="errors.first('limit_coupon_member')" class="custom-invalid-feedback" >{{ errors.first('limit_coupon_member') }}</div>
                                </div>
                                <div class="col-md-12 form-group">
                                    <label for="">{{ $t('coupons.tiered_boundaries') }} :</label>

                                    <select-dropdown-multiple
                                        :is-disabled="modeView"
                                        :render-select="loyaltyTierRenderSelect"
                                        repo-name="loyaltyTierRepository"
                                        @update:changedValue="onLoyaltyTierSelected"
                                        ref="loyaltyTierSelectVue"
                                        :selected-dropdown="loyaltyTierIds"
                                        :id-select-dropdown="'tiers_' + couponModel.id"
                                        ></select-dropdown-multiple>
                                    <div v-show="errors.first('tiers')" class="custom-invalid-feedback" >{{ errors.first('tiers') }}</div>
                                </div>



                                </div>
                            </div>
                            <div class="col-md-4 form-group">

                                    <label for="">{{ $t('common.gambar') }}* :</label>
                                    <div class="dropzone" v-if="!modeView" id="kt-dropzone-one" action="gallery/api/photo" >
                                        <div class="dropzone-msg dz-message needsclick" >
                                            <img v-if="!modeView && dataEdit && !imgSelected" v-bind:src="getPathImage" style="width:100%" />
                                            <h1>
                                                <i class="fa fa-upload" aria-hidden="true"></i>
                                            </h1>
                                            <h3 class="dropzone-msg-title">Upload</h3>
                                            <span class="dropzone-msg-desc">
                                                {{$t("sentences.drag_image_here_or_click_to_")}}
                                            </span>
                                            <div v-show="errors.first('selected_image')" class="custom-invalid-feedback">
                                                {{errors.first("selected_image")}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12" v-if="modeView">
                                        <img v-bind:src="getPathImage" style="width:100%" />
                                    </div>

                            </div>
                                <div class="col-md-6 form-group">
                                    <label for="">{{ $t('coupons.terms_and_conditions') }} :</label>
                                    <div v-if="!modeView" id="summernote_terms_and_conditions"></div>
                                    <div v-if="modeView" v-html="couponModel.terms_and_conditions"></div>
                                    <div v-show="errors.first('terms_and_conditions')" class="custom-invalid-feedback" >{{ errors.first('terms_and_conditions') }}</div>
                                </div>
                                <div class="col-md-6 form-group">
                                    <label for="">{{ $t('coupons.how_to_use') }} :</label>
                                    <div v-if="!modeView" id="summernote_how_to_use"></div>
                                    <div v-if="modeView" v-html="couponModel.how_to_use"></div>
                                    <div v-show="errors.first('how_to_use')" class="custom-invalid-feedback" >{{ errors.first('how_to_use') }}</div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <button
                                type="button"
                                class="btn btn-clean btn-bold btn-upper btn-font-md"
                                @click="hideModal()"
                            >
                                {{ $t("button.close") }}
                            </button>
                            <button
                                v-if="!modeView"
                                type="submit"
                                class="btn btn-default btn-bold btn-upper btn-font-md"
                            >
                                {{ $t("button.save") }}
                            </button>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</template>
<script>
import CouponModel from "./../../model/coupon-model";
import { Validator } from "vee-validate";
import VeeValidate from "vee-validate";
import DateTimePicker from "./../../components/base/DateTimePicker";
import SelectDropdownMultiple from "./../../components/base/SelectDropdownMultiple";
import Photo from "./../../model/photo-model";
import { mapState, mapMutations, mapActions } from "vuex";
import SweetAlertTemplate from "./../../../components/_general/SweetAlert";
import CouponsRepository from '../../repositories/CouponsRepository';
import BlockUI from "./../../../components/_js/BlockUI";
import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";

const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);
const GalleryRepository = RepositoryFactory.get("galleryRepository");

VeeValidate.setMode('passive');
export default {
    components:{ SelectDropdownMultiple, DateTimePicker},
    computed: {
        ...mapState("Crud", {
            modalName: state => state.modalName,
            dataEdit: state => state.dataEdit,
            showModalMode: state => state.showModalMode,
            isShowModal: state => state.isShowModal,
        }),

        getPathImage(){
            let result = this.dataEdit.image_path;
            if(result){
                let slash = this.dataEdit.image_path.indexOf('/')===0? '':'/';
                result = this.dataEdit.image_path.indexOf('http')
                    === 0 ? this.dataEdit.image_path : this.urlImage + slash + this.dataEdit.image_path;
            }
            return result;
        },
        loyaltyTierIds(){
            if(this.dataEdit){
                return _.map(this.dataEdit.tiers,'id');
            }
            return [];
        },
        kategoriPromoIds(){
            if(this.dataEdit){
                return _.map(this.dataEdit.categories,'id');
            }
            return [];
        },
        modeEdit(){
            return (this.dataEdit && this.dataEdit.id) || this.modeView;
        },
        modeView(){
            return this.showModalMode == 'V';
        },
    },
    provide() {
        return { $validator: this.$validator };
    },
    data(){
        return {
            couponModel:new CouponModel(),
            photo: new Photo(),
            listPhoto : [],
            imgSelected:false,
            isMaxfilesExceeded: false,
            urlUpload: process.env.VUE_APP_API_URL + "/gallery/api/photos",
            urlImage:  process.env.VUE_APP_API_URL + "/gallery/api/image/alt"
        }
    },
    methods: {
        ...mapMutations({
            clearData: "Crud/CLEAR_DATA",
            showModal: "Crud/SHOW_MODAL",
            refreshData: "Crud/REFRESH_TABLE",
            setEditData: "Crud/SET_EDIT_DATA",
            hideModal: "Crud/HIDE_MODAL",
        }),

        getServerError(error, defaultMsg) {
            return _.isUndefined(error.response.data.message)? defaultMsg : error.response.data.message;
        },
        childValidator(fields){

        },
        randomString(len,block) {
            let chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
            let text =[];
            for(let b=0;b< block ;b++){
                let blockText ="";
                for( let i=0; i < len; i++ ) {
                    blockText += chars.charAt(Math.floor(Math.random() * chars.length));
                }
                text.push(blockText);
            }
            return text.join('-');
		},
        generateCouponCode(){
            this.couponModel = Object.assign(this.couponModel,{code:this.randomString(7,4)});
        },
        loyaltyTierRenderSelect(val){
            return (val.name) ? val.name : '';
        },
        kategoriPromoRenderSelect(val){
            return (val.name) ? val.name : '';
        },
        onLoyaltyTierSelected(value){
            try{
                this.couponModel = Object.assign(this.couponModel,{
                    tiers:value
                });
            }
            catch(e){ }
            $('#tiers_' + this.couponModel.id).parent().removeClass('is-invalid');
            this.errors.remove('tiers');
        },

        changedExpiredDate(value){
            try{
                this.couponModel = Object.assign(this.couponModel,{
                    expired_date:value
                })
            }catch(e){

            }
            $('#expired_date_'+this.couponModel.id).removeClass('is-invalid');
            this.errors.remove('expired_date');
        },
        onKategoriPromoSelected(value){

            try{
                this.couponModel = Object.assign(this.couponModel,{
                    categories:value
                });
            }
            catch(e){ }
            $('#categories_' + this.couponModel.id).parent().removeClass('is-invalid');
            this.errors.remove('categories');
        },

        async update(payload,couponId) {
                var vx = this;
                payload.tiers = _.map(payload.tiers,'id').join(',');
                payload.categories = _.map(payload.categories,'id').join(',');
                await CouponsRepository.update(payload,couponId)
                    .then(response => {
                        new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Kupon berhasil diubah', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function() {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                            }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: vx.getServerError(
                            error,
                            "Data Kupon gagal diubah."
                        ),
                        swalType: "error",
                        onConfirmButton: function() {
                            blockUI.unblockModal();
                        }
                    });
                });
        },
        async create(payload) {
            var vx = this;
            await CouponsRepository.create(payload)
                .then(response => {
                    new Promise((resolve, reject) => {
                            vx.$emit("event", resolve);
                            resolve(response);
                        }).
                        then((response) => {
                            let instance = new SweetAlert().showInfo({
                            swalTitle: "Berhasil!",
                            swalText: 'Data Kupon berhasil ditambahkan', // response.data.message,
                            swalType: "success",
                            onConfirmButton: function () {
                                vx.refreshData(true);
                                blockUI.unblockModal();
                                vx.hideModal();
                                }
                            });
                        }).catch(error=>{

                        });
                    })
                    .catch(error => {
                        let instance = new SweetAlert().showInfo({
                            swalTitle: "Gagal!",
                            swalText: vx.getServerError(
                                error,
                                "Data Kupon gagal ditambahkan."
                            ),
                            swalType: "error",
                            onConfirmButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    });
            },
        submitData(){
            this.errors.clear();
            this.$validator.validateAll().then(result => {
                let fields = {
                    'categories':this.$t('common.kategori')
                    };
                let valid = true;

                _.each(fields,(fieldAlias,keyName)=>{
                    let elTarget = $(`#${keyName}_${this.couponModel.id}`);
                    elTarget.parent().removeClass('is-invalid');
                    if( this.couponModel[keyName].length==0 ){
                        this.errors.add({field: keyName, msg: `${fieldAlias} Wajib diisi`, rule: "required"});
                        elTarget.parent().addClass('is-invalid');
                        valid = false;
                    }
                });

                if(this.couponModel.expired_date == null || this.couponModel.expired_date == ""){
                    $('#expired_date_'+this.couponModel.id).addClass('is-invalid');
                    this.errors.add({field: 'expired_date', msg: this.$t('coupons.valid_until')+' Wajib diisi', rule: "required"});
                    valid = false;
                }

                if(this.couponModel.image_path == null){
                    this.errors.add({
                        field: "selected_image",
                        msg: 'Silahkan pilih Gambar', // this.$t("sentences.invalid_attachments_new"),
                        rule: "required"
                    });
                    valid = false;
                }


                if (result && valid) {
                    var vx = this;
                    if (this.couponModel.id) {

                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                if(vx.couponModel.limit_coupon === undefined || vx.couponModel.limit_coupon === null || vx.couponModel.limit_coupon === '' ){
                                    vx.couponModel.limit_coupon = '0';
                                }
                                vx.update(vx.couponModel, vx.couponModel.id);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    } else {
                        let instance = new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Data akan ditambahkan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                // const data = new FormData();
                                const data = {};
                                let arrToString = ['tiers','categories'];
                                if(vx.couponModel.limit_coupon === undefined || vx.couponModel.limit_coupon === null || vx.couponModel.limit_coupon === '' ){
                                    vx.couponModel.limit_coupon = '0';
                                }
                                _.forEach(vx.couponModel, (v, k) => {
                                    if(v != null){
                                        if(arrToString.indexOf(k) !== -1){
                                            v = _.map(v,'id').join(',');
                                        }
                                        // data.append(k, v);
                                        data[k] = v;
                                    }
                                });
                                vx.create(data);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
                        });
                    }

                    return;
                }
                else{

                }
            });
        },
        summerNote(idEl,keyNameModel,contentCode){

            let config= {
                height:150,
                callbacks: {
                    onChange: (contents)=>{
                        this.couponModel[keyNameModel]=contents;
                    },
                    onBlurCodeview:(contents, $editable)=>{
                        this.couponModel[keyNameModel]=contents;
                    },
                }
            };
            $(idEl).summernote(config);

            if('string'==typeof(contentCode)){
                $(idEl).summernote('code',contentCode);
            }
            else if( null !== this.dataEdit && !_.isUndefined(this.dataEdit[keyNameModel])){
                $(idEl).summernote('code',this.dataEdit[keyNameModel]);
            }

        },
        updateData() {
            if (this.dataEdit) {
                this.couponModel = Object.assign({}, this.dataEdit);
            }

        },
        cropImage(myDropzone, file, done){
            var vx = this;
            if(vx.isMaxfilesExceeded){
                this.removeFile(file);
                let sa = new SweetAlert().showInfo({
                    swalTitle: "Max files",
                    swalText: "Max files Exceeded.",
                    swalType: "warning"
                });
                return;
            }
            if (file.cropped) {
                return;
            } else {
                var editor = document.createElement("div");
                editor.style.position = "fixed";
                editor.style.left = 0;
                editor.style.right = 0;
                editor.style.top = 0;
                editor.style.bottom = 0;
                editor.style.zIndex = 9999;
                editor.style.backgroundColor = "#000";
                document.body.appendChild(editor);

                // Create confirm button at the top left of the viewport
                var buttonConfirm = document.createElement(
                    "button"
                );
                buttonConfirm.style.position = "absolute";
                buttonConfirm.style.right = "10px";
                buttonConfirm.style.top = "10px";
                buttonConfirm.setAttribute(
                    "class",
                    "btn btn-primary"
                );
                buttonConfirm.style.zIndex = 9999;
                buttonConfirm.textContent = vx.$i18n.t(
                    "button.crop"
                );
                editor.appendChild(buttonConfirm);
                buttonConfirm.addEventListener("click", function() {
                    // Get the canvas with image data from Cropper.js
                    var canvas = cropper.getCroppedCanvas({
                        width: 512,
                        height: 256
                    });
                    // Turn the canvas into a Blob (file object without a name)
                    canvas.toBlob(function(blob) {
                        myDropzone.createThumbnail(
                            blob,
                            myDropzone.options.thumbnailWidth,
                            myDropzone.options.thumbnailHeight,
                            myDropzone.options.thumbnailMethod,
                            false,
                            function(dataURL) {
                                file.cropped = true;
                                file.dataURL = dataURL;
                                myDropzone.emit(
                                    "thumbnail",
                                    file,
                                    dataURL
                                );
                                // Return the file to Dropzone
                                if(typeof done == 'function'){
                                    new Promise((resolve, reject) => {
                                        const reader = new FileReader();
                                        reader.readAsDataURL(blob);
                                        reader.onload = function(event) {
                                            let photo = new Photo(); // bikin object payload image untuk di post ke API
                                            var image = new Image(); // bikin object image kosong
                                            image.src = event.target.result; // isi object image kosong dari file yang diupload
                                            image.onload = function(i) { // saat load image masukan data image ke object payload image
                                                photo.image = event.target.result.substring(
                                                    event.target.result.indexOf(",") + 1
                                                ); // base64 image
                                                photo.title = file.name; // nama file
                                                photo.description = file.name; // deskripsi biasanya pake nama juga
                                                photo.imageContentType = file.type; // tipe file
                                                photo.height = this.height; // tinggi foto
                                                photo.width = this.width; // lebar foto
                                                photo.taken = moment(file.lastModified); // waktu pengambilan
                                                photo.uploaded = moment(); // waktu upload
                                                photo.uuid = file.upload.uuid; // uuid untuk membedakan proses file upload
                                                resolve(photo);
                                            };
                                        };
                                    }).then(photo => {
                                        vx.listPhoto.push(photo); // masukan foto kedalam listphoto
                                        done(blob); // setelah proses masuk list photo selesai maka lanjutkan proses on sending
                                    });
                                    // done(blob);
                                }
                            }
                        );
                    });
                    // Remove the editor from the view
                    document.body.removeChild(editor);
                });

                var buttonCancel = document.createElement(
                    "button"
                );
                buttonCancel.style.position = "absolute";
                buttonCancel.style.left = "10px";
                buttonCancel.style.top = "10px";
                buttonCancel.setAttribute("class","btn btn-danger");
                buttonCancel.style.zIndex = 9999;
                buttonCancel.textContent = "Cancel";
                editor.appendChild(buttonCancel);
                buttonCancel.addEventListener("click", function() {
                    myDropzone.removeFile(file);
                    document.body.removeChild(editor);
                });

                // Create an image node for Cropper.js
                var image = new Image();
                image.src = URL.createObjectURL(file);
                editor.appendChild(image);

                // Create Cropper.js
                var cropper = new Cropper(image, {
                    aspectRatio: 2/1
                });
            }
        },
        dropZone() {
            var vx = this;
            if(!this.modeView){
                let dropzoneConfig = {
                    paramName: "file", // The name that will be used to transfer the file
                    maxFiles: 1,
                    maxFilesize: 2, // MB
                    addRemoveLinks: true,
                    thumbnailWidth: 512,
                    thumbnailHeight: 256,
                    acceptedFiles: "image/jpeg,image/png,image/jpg,image/gi",
                    resizeWidth: true,
                    resizeQuality: 0.7,
                    autoProcessQueue: true,
                    createImageThumbnails: true,
                    url: this.urlUpload,
                    headers: {  // masukan header yang dibutukan bersama token
                        Accept: "application/json, text/plain, */*",
                        Authorization: "Bearer " + localStorage.tokens,
                        "cache-control": "no-cache",
                        "Content-Type": "application/json;charset=UTF-8"
                    },
                    transformFile: function(file, done) {
                        vx.cropImage(this,file,done);
                    },
                    init: function() {
                        //proses pengiriman/post  gambar ke API disini kita overwrite plugin pengiriman nya pake json bukan formdata
                        // onsending dilakukan perfile berdasarkan total file yang kita pilih pas upload
                        this.on("sending", function(file, xhr, formData) {
                           // cari object photo untuk di post berdasarkan proses file yang dilakukan
                            let photo = vx.listPhoto.find(x => x.uuid == file.upload.uuid);
                            // kirim object photo
                            const x = JSON.stringify(photo);
                            xhr.send(x);
                        });
                        // kalau success upload nya maka anda akan mendapatkan response alias untuk disimpan
                        // success masuk per proses upload
                        this.on("success", function(file, response) {
                            // klau disini responsenya dibuat sebuat object lalu di push ke object utama
                            vx.couponModel.image_path = response.alias;
                            // jangan lupa hapus object photo dari list photo berdasarkan uuid proses
                            const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                            if (i > -1) {
                                vx.listPhoto.splice(i, 1);
                            }
                            // load image/thumbnail
                            file.previewElement.querySelector("img").src = vx.urlImage + '/' + response.alias;
                        });
                        this.on("error", function(file, message) {
                            vx.couponModel.image_path = null;
                            //kalau upload gagal tambilkan message error nya dan jangan lupa hapus proses di listphoto
                            const i = vx.listPhoto.findIndex(x => x.uuid == file.upload.uuid);
                            if (i > -1) {
                                vx.listPhoto.splice(i, 1);
                            }
                            $(file.previewElement)
                                .addClass("dz-error")
                                .find(".dz-error-message")
                                .text(
                                    "Upload Gagal, Silahkan hapus gambar dan coba upload kembali"
                                );
                        });

                        this.on("removedfile", function(file) {
                            if(vx.isMaxfilesExceeded) return;
                            vx.couponModel.image_path = null;
                            vx.imgSelected = true;
                        });
                    },
                }; //config
                var myDropzone = new Dropzone("#kt-dropzone-one", dropzoneConfig);
                myDropzone.on("addedfile", file => {
                    vx.isMaxfilesExceeded = false;
                    const fileName = file.name;
                });
                myDropzone.on("maxfilesexceeded", file => {
                    vx.isMaxfilesExceeded = true;
                });
            }

        }, //dropzone()
        dataPhotoToFile(photo) {
            let bstr = atob(photo.image),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            let newFile = new File([u8arr], photo.originName, {
                type: photo.imageContentType
            });
            return newFile;
        }
    },
    created(){

        VeeValidate.Validator.extend('verify_coupon_code', {
            getMessage: field => this.$t('coupons.verify_coupon_msg'),
            validate: value => {
                let testRegex = new RegExp("^[a-zA-Z0-9-]+$");
                return testRegex.test(value);
            }
        });
    },
    mounted() {
        if(!this.modeView){
            this.summerNote('#summernote_how_to_use','how_to_use');
            this.summerNote('#summernote_terms_and_conditions','terms_and_conditions');
        }
        $("#" + this.modalName).modal();
        this.dropZone();
        this.updateData();
    }
}
</script>

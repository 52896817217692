export default function CouponModel() {
    return {
        id:null,
        code:null,
        title:null,
        subtitle:null,
        image_path:null,
        point:null,
        expired_date:null,
        limit_coupon:null,
        limit_coupon_member:null,
        tiers:[],
        categories:[],
        terms_and_conditions:null,
        how_to_use:null
    };
}

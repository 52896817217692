<template>
    <p>{{ getLimit }}</p>
</template>

<script>
export default {
    props: ["data", "index", "column"],
    computed:{
            getLimit(){
                let limitCoupon = this.data.limit_coupon;
                if( limitCoupon === null || limitCoupon  === undefined || limitCoupon == 0){
                    limitCoupon = ' ∞ ';
                }

                let usedCoupon = this.data.used;
                if( usedCoupon === null || usedCoupon  === undefined){
                    usedCoupon = 0
                }
                return `${usedCoupon}/${limitCoupon}`
            }
        }
}
</script>
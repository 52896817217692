 <<template>
    <p>{{ tiers }}</p>
 </template>
 <script>
 export default {
     props: ["data", "index", "column"],
     computed:{
            tiers(){
                return _.map(this.data.categories,'name').join(", ");
            }
        }
 }
 </script>

<template>
    <div>
        <base-header :base-title="$t('coupons.title')"></base-header>
        <div class="coupons kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="$t('coupons.title')"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <template
                            v-if="checkPermission('LoyaltyCouponResource.POST.store')">
                            &nbsp;&nbsp;
                            <base-button-modal
                            :button-text="$t('coupons.add')"
                            button-icon="flaticon2-add-1"
                            :button-name="modalName"
                            :onButtonModalClicked="showModal"
                            ></base-button-modal>
                        </template>
                    </div>
                </div>
            </div>

            <div class="kt-portlet__body">
                <crud-table
                ref="crudTable"
                :table-options="tableOptions"
                :repository="repository"
                :permission="permission"
                :event="fetch"
                @on-click-edit="onClickEdit"
                @on-click-delete="onClickDelete"
                @on-click-view="onClickView"
                ></crud-table>
            </div>

            <form-modal v-if="isShowModal" @event="fetch"></form-modal>
        </div>
    </div>
</div>
</template>
<script>
    import BaseHeader from "./../../../components/_base/BaseHeader";
    import BaseTitle from "./../../../components/_base/BaseTitle";
    import BaseButtonModal from "./../../../components/_base/BaseButtonModal";
    import CheckPermission from "./../../../services/checkPermission.service";
    import CrudTable from "./../base/crud-table/crudTable";
    import ColumnHtml from "./../base/crud-table/ColumnHtml";
    import { RepositoryFactory } from "./../../repositories/BaseRepositoryFactory";
    import { isNullOrUndefined } from "util";
    import FormModal from './FormModal';
    const couponsRepository = RepositoryFactory.get("couponsRepository");
    import { mapState, mapMutations, mapActions } from "vuex";
    const checkPermission = new CheckPermission();
    import ColumnImageCoupons from '@/components/_general/columnTableLoyalty/ColumnImageCoupons.vue'
    import ColumnCategories from '@/components/_general/columnTableLoyalty/ColumnCategories.vue'
    import ColumnCode from '@/components/_general/columnTableLoyalty/ColumnCode.vue'
    import ColumnTiers from '@/components/_general/columnTableLoyalty/ColumnTiers.vue'
    import ColumnLimitCoupon from '@/components/_general/columnTableLoyalty/ColumnLimitCoupon.vue'

    export default {
        created(){
            this.setRepository(couponsRepository)
            this.setModal("LoyaltyCouponModal")
            this.hideModal();
        },
        components:{ BaseHeader,BaseTitle,BaseButtonModal,CrudTable,FormModal },
        methods:{
            ...mapMutations({
                setRepository: 'Crud/SET_REPOSITORY',
                refreshData: 'Crud/REFRESH_TABLE',
                showModal:'Crud/SHOW_MODAL',
                hideModal:'Crud/HIDE_MODAL',
                setModal: 'Crud/SET_MODAL',
                setupTable: 'Crud/SETUP_TABLE',
                setEditData: 'Crud/SET_EDIT_DATA',
                setViewData: 'Crud/SET_VIEW_DATA',
            }),
            checkPermission(method) {
                return checkPermission.isCanAccess(method);
            },
            fetch(){
                this.refreshData(true);
                this.hideModal();
            },

            onClickEdit(data) {
                this.$store.commit("Crud/SET_EDIT_DATA", data);
                this.$store.commit("Crud/SHOW_MODAL");

            },
            onClickDelete(data) {
                this.$store.dispatch("Crud/deleteById", {
                    deleteRepository: couponsRepository.delete,
                    id: data.id,
                    name: data.title
                });
            },
            onClickView(data) {
                this.$store.commit("Crud/SET_VIEW_DATA", data);
                this.$store.commit("Crud/SHOW_MODAL");
            }

        },
        watch:{
            isShowModal(isTrue){
                if (!isTrue) {
                    this.setEditData(null);
                    let modalBackdrop = document.getElementsByClassName(
                        "modal-backdrop"
                    )[0];
                    if (!_.isUndefined(modalBackdrop)) {
                        modalBackdrop.parentNode.removeChild(modalBackdrop);
                    }
                }
            },

            shouldRefreshTable: function(newValue, oldValue) {
                if (newValue === true) {
                    this.$refs.crudTable.refreshTable();
                }
            }
        },
        computed:{
            ...mapState("Crud", {
                modalName: state => state.modalName,
                isLoaded: state => state.isLoaded,
                table: state => state.table,
                isShowModal: state => state.isShowModal,
                showModalMode: state => state.showModalMode,
                shouldRefreshTable: state => state.shouldRefreshTable,
            })
        },
        data(){
            var vx = this;
            return {
                repository: couponsRepository,
                permission: {
                    edit: this.checkPermission('LoyaltyCouponResource.PUT.update.Integer.key'),
                    delete: this.checkPermission('LoyaltyCouponResource.DELETE.destroy.Integer.key'),
                    view: this.checkPermission('LoyaltyCouponResource.GET.show.Integer.key')
                },
                tableOptions: {
                    name: "CRUD_TABLE",
                    columns: [
                        'id', 'rCode', 'title', 'subtitle', 'rImageRender', 'point', 'expired_date', 'rLimitCoupon','limit_coupon_member', 'rTiers', 'rCategories', 'rTermsAndConditions', 'rHowToUse','actions'
                    ],
                    sortable: ['id', 'rCode', 'title', 'subtitle', 'point', 'expired_date', 'rLimitCoupon', 'limit_coupon_member','rTiers', 'rCategories', 'rTermsAndConditions', 'rHowToUse'],
                    orderBy: { column: 'id', ascending:false },
                    headings: {
                        id: "ID",
                        rCode:this.$t('coupons.coupon_code'),
                        title:this.$t('common.judul'),
                        subtitle:this.$t('common.subJudul'),
                        rImageRender:this.$t('common.gambar'),
                        point:this.$t('coupons.exchange_points'),
                        expired_date:this.$t('coupons.valid_until'),
                        rLimitCoupon:this.$t('coupons.usage_limit'),
                        limit_coupon_member:this.$t('coupons.usage_limit_member'),
                        rTiers:this.$t('coupons.tiered_boundaries'),
                        rCategories:this.$t('common.kategori'),
                        rTermsAndConditions:this.$t('coupons.terms_and_conditions'),
                        rHowToUse:this.$t('coupons.how_to_use'),
                    },
                    customColumn: {
                        rCode:'code',
                        rLimitCoupon:'limit_coupon',
                        rHowToUse: 'how_to_use',
                        rImageRender: 'image_path',
                        rTermsAndConditions:'terms_and_conditions',
                        rCategories:'categories',
                        rTiers:'tiers',
                    },
                    columnsFilter: [
                        { key: "id", type: "number" },
                        { key: 'rCode', type:"string" },
                        { key: 'title', type:"string" },
                        { key: 'subtitle', type:"string" },
                        { key: 'point', type:"number" },
                        { key: 'expired_date', type:"date" },
                        { key: 'rLimitCoupon', type:"number" },
                        { key: 'limit_coupon_member', type:"number" },
                        // { key: 'rTiers', type:"string" },
                        // { key: 'rCategories', type:"string" },
                        { key: 'rTermsAndConditions', type:"string" },
                        { key: 'rHowToUse', type:"string" },

                    ],
                    customListFilter: {
                        rTiers : {
                            repo : 'loyaltyTierRepository',
                            values: [],
                            renderSelect : function(val){
                                return (val.name) ? val.name : '';
                            },
                            type: 'multiselect',
                        },
                        rCategories : {
                            repo : 'kategoriPromo',
                            values: [],
                            renderSelect : function(val){
                                return (val.name) ? val.name : '';
                            },
                            type: 'multiselect',
                        },
                    },
                    templates: {
                        rCode:ColumnCode,
                        rLimitCoupon: ColumnLimitCoupon,
                        rHowToUse: ColumnHtml,
                        rTermsAndConditions:ColumnHtml,
                        rImageRender: ColumnImageCoupons,
                        rCategories:ColumnCategories,
                        rTiers:ColumnTiers
                    },

                }
            }
        }
    }
</script>

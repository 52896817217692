<template>
    <p>{{ tiers }}</p>
</template>

<script>
export default {
        props: ["data", "index", "column"],
        computed:{
        tiers(){
            return (this.data.tiers.length==0)? '-' : _.map(this.data.tiers,'name').join(", ");
        }
    }
}
</script>